
















import { Store, TAwait, TElement } from '@/store/store'
import TableLoading from './TableLoading.vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components:{
    TableLoading
  }
})
export default class LicenseChanges extends Store {
  @Prop(Number) readonly accountId!: number
  @Prop(String) readonly Lkey!: string

  //@ts-ignore
  items: TElement<TAwait<ReturnType<typeof Store.prototype.getListOfLicensesAssociatedWithAnAccount>>>['changes'] | null = null

  get fields(){
    return [
      {
        key: 'id',
        label: 'ID',
      },
      'message',
      'created_at',
      {
        key: 'is_cancelled',
        label: 'Cancelled',
      },
    ]
  }

  async mounted(){
    this.refresh()  
  }
  async refresh(){
    const items = (await this.getListOfLicensesAssociatedWithAnAccount(this.accountId))?.values()
    if(!items) return this.$toast.error('')
    const e = [...items].find(i => i.key == this.Lkey)
    if(!e) return
    //@ts-ignore
    this.items = e.changes
  }

  cancelChange(id: number){
    this.$toast.error('Not Implemented Yet')
  }
}
