
















































































// @vuese
import { Prop, Emit, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component';
import { Store, TAwait, TElement, Types } from '@/store/store';
import moment from 'moment'
/**
 * Gets the details of a license and renders to the screen in a particular format
 */

@Component
export default class LicenseSummary extends Store {
  /**
   * Key of the license. Typially a single string known by the databse
   */
  @Prop() protected readonly Lkey!: string
  @Prop({default: false}) readonly add!: boolean
  @Prop() readonly accountId!: number
  // @Prop() readonly errors!: Array<string>

  // thisAccount details
  companyDetails: any = null

  edit = true
  availableLicenses = 0
  assignedLicenses = 0
  numberOfLicenses = 0

  usedMonthly = 0
  usedAnnually = 0

  license: any = null

  newMonthlyPrice: string | null = null
  newAnnualPrice: string | null = null

  billingCycleOptions = ['month', 'year']
  billingCycle = this.billingCycleOptions[0]
  billingCycleName = ''

  async mounted(){
    this.refresh()
  }

  async refresh(){
    this.companyDetails = await this.getAccount(+this.$route.params.account_id);
    this.license = null
    let items: any
    if(this.isAdmin){
      items = await this.getListOfLicensesAssociatedWithAnAccount(this.accountId)
      const x = (await this.getListOfAssingableLicenses())
      x?.forEach((i, index)=>{
        const query = items.find((q: any) => q.key == i.key)
        if(query) x[index] = query

      })
      items = x
      
    }
    else if(this.isDistributor){
      items = (await this.getListOfAssingableLicenses())
    }

    if(!items) return
    
    if(!items || !items.length) return this.$toast.error('Unable To Find This Licesnse')
    const arr = items
    const item = arr.find((i: any) => i.key == this.licenceKey)
    
    if(!item) return
    this.license = {...item}

    this.populateValues()

    // Set default select option
    if(!this.license.billing_cycle)
    this.license.billing_cycle = this.billingCycleOptions[0]

    this.availableLicenses = item.available
  }

  // Key is a combination of name and billing-cycle e.g. 'pro-month' so that it can be unique and trigger a view update
  // We splt the key to get our license key and billing-cycle
  get licenceKey(): string {
    const L = this.Lkey.split('_');
    return L[0];
  }

  get licenceBillingCycle(): string {
    const L = this.Lkey.split('_');
    return L[1] ? L[1] : this.billingCycle
  }

  get isPendingChanges(): boolean {
    return this.license?.prices_scheduled_to_be_live_at && (this.license.scheduled_monthly_price || this.license.scheduled_monthly_price)
  }

  get allowToSetDate(): boolean {
    return this.license?.assigned && (this.newMonthlyPrice || this.newAnnualPrice)
  }

  get priceChanged(): string | null {
    return this.newAnnualPrice || this.newMonthlyPrice
  }

  get minAmount(): number {
    return this.usedMonthly + this.usedAnnually;
  }

  get tooFew(): boolean {
    return this.numberOfLicenses < this.minAmount;
  }

  get addOrRemove(): string {
    return this.assignedLicenses > this.numberOfLicenses ? 'Remove' : 'Add';
  }

  populateValues(): void {
    // Set defaults
    this.assignedLicenses = 0
    this.usedMonthly = 0
    this.usedAnnually = 0
    this.license.billing_cycle = this.licenceBillingCycle
    this.billingCycleName = this.license.billing_cycle + 'ly'

    // Pre-populate number of licenses with assigned amount
    if (this.license && this.license.key && this.companyDetails.licenses[0] && this.companyDetails.licenses[0].key) {

      let selectedLicense

      // If the licenses have a billing cycle defined, filter by it
      if (this.companyDetails.licenses[0].billing_cycle) {
        selectedLicense = this.companyDetails.licenses.filter((obj: any) => obj.key == this.license.key && obj.billing_cycle == this.licenceBillingCycle)
      } else {
        selectedLicense = this.companyDetails.licenses.filter((obj: any) => obj.key == this.license.key)
      }

      if(selectedLicense.length > 0) {
        this.assignedLicenses = selectedLicense[0].assigned
        this.numberOfLicenses = selectedLicense[0].assigned
        this.usedMonthly = (selectedLicense[0].used_monthly ? selectedLicense[0].used_monthly : 0)
        this.usedAnnually = (selectedLicense[0].used_annual ? selectedLicense[0].used_annual : 0)
        this.license.billing_cycle = this.licenceBillingCycle
        this.billingCycleName = this.license.billing_cycle + 'ly'
      }
    }
  }

  @Emit()
  submit(){
    if(!this.license) return
    
    return {
      count: this.numberOfLicenses,
      discounted_monthly_price: this.newMonthlyPrice !== null ? this.newMonthlyPrice : this.license.discounted_monthly_price,
      discounted_annual_price: this.newAnnualPrice !== null ? this.newAnnualPrice : this.license.discounted_annual_price,
      ...(this.license.prices_scheduled_to_be_live_at && { prices_scheduled_to_be_live_at: this.allowToSetDate ? moment(this.license.prices_scheduled_to_be_live_at).format('YYYY-MM-DD') : null}),
      ...(this.license.billing_cycle && { billing_cycle: this.license.billing_cycle}),
      key: this.license.key 
    }
  }
}
