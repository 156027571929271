










































































































































// @vuese
// @group Views

interface Errors {
  licenseCount: Array<string>;
  billingCycle: Array<string>;
}

import { Store, TAwait, Types } from "@/store/store";
import Component from "vue-class-component";
import LicenseInventoryTable from "@/components/tables/LicenseInventoryTable.vue";
import TrialLicenseTable from "@/components/tables/TrialLicenseTable.vue";
import LicenseSummary from "@/components/cards/LicenseSummary.vue";
import ListOfLicenses from "@/components/ListOfLicenses.vue";
import Card from "@/components/cards/Card.vue";
import GenericPopup from "@/components/popup/GenericPopup.vue";
import LicenseChanges from "@/components/tables/LicenseChanges.vue";
import TrialOverridePopup from "@/components/popup/ConfirmationPopup.vue";
import { v4 as uuid } from "uuid";

@Component({
  components: {
    LicenseInventoryTable,
    TrialLicenseTable,
    LicenseSummary,
    ListOfLicenses,
    Card,
    GenericPopup,
    LicenseChanges,
    TrialOverridePopup,
  },
  metaInfo: {
    title: "License Manager",
  },
})
/**
 * This view allows a thisAccount to see the licesnes associated with an thisAccount.
 * It also allows a thisAccount to assign licenses the queried thisAccount
 */
export default class LicenseManager extends Store {
  // thisAccount details
  thisAccount?: TAwait<ReturnType<typeof Store.prototype.getAccount>> | null = null;

  companyDetails?: TAwait<
    ReturnType<typeof Store.prototype.getCompanyAccount>
  > | null = null;

  isAdding = false;

  // The license selected to be queried
  selectedLicense: string | null = null;

  // refresh's any component that needs to be re-mounted
  reloadKey = uuid();

  // Dictates if the the `available licenses` card is visible on the screen
  listOfAvailableLicensesIsVisible = false;

  errors: Errors = {
    licenseCount: [],
    billingCycle: [],
  };

  isChangesPopupVisible: string | null = null;
  isTrialOverridePopupVisible = false;
  isOnTrial__OVERRIDE = false;

  reRender: number = 0

  /**
   * refresh the data
   * @vuese
   */
  async refresh() {
    this.hideTrialOverridePrompt();
    this.clearErrors();
    this.thisAccount = await this.getAccount(+this.$route.params.account_id);
    this.reloadKey = uuid();
    this.hideListOfAvailableLicenses();
    this.isOnTrial__OVERRIDE = false;      
  }
  async mounted() {
    await this.refresh();

    if (this.thisAccount?.role === "provisioned_company") {
      const x = await this.getCompanyAccount(this.$route.params.id);
      this.companyDetails = x;
    }
  }

  get licenceKey(): string {
    if(this.selectedLicense) {
      const L = this.selectedLicense.split('_');
      return L[0];
    }
    return '';
  }

  get licenceBillingCycle(): string | null {
    if(this.selectedLicense) {
      const L = this.selectedLicense.split('_');
      return L[1] && L[1] != 'undefined' ? L[1] : '';
    }
    return null;
  }

  get componentTitle(): string {
    return this.isOnTrial ? "trial_license_manager" : "license_manager";
  }

  get userName(): string | null | undefined {
    return this.thisAccount && this.thisAccount.business_profile.business_name ? this.thisAccount.business_profile.business_name : '';
  }

  get isOnTrial(): boolean {
    if(!this.thisAccount || this.isOnTrial__OVERRIDE) return false;
    // @ts-ignore
    return this.thisAccount && this.thisAccount.licenses.length ? false : true;
  }

  showTrialOverridePrompt() {
    this.isTrialOverridePopupVisible = true;
  }
  hideTrialOverridePrompt() {
    this.isTrialOverridePopupVisible = false;
  }
  trialConfirmation() {
    this.isOnTrial__OVERRIDE = true;
    this.showListOfAvailableLicenses();
  }

  /**
   * call this function to show the `license summary` card
   * @vuese
   */
  showLicenseSummary(license: string) {
    this.reRender++
    // Render to screen
    this.clearErrors();
    this.selectedLicense = license
  }

  hideLicenseSummary() {
    this.selectedLicense = null;
  }

  showChanges(license: string) {
    this.isChangesPopupVisible = license;
    this.selectedLicense = license
  }

  hideChanges() {
    this.isChangesPopupVisible = null;
  }

  // Errors

  mapErrors(errors: any) {
    // Profile
    this.errors.licenseCount =
      errors["licenses.0.count"] && errors["licenses.0.count"].length
        ? errors["licenses.0.count"]
        : [];
    this.errors.billingCycle =
      errors["licenses.0.billing_cycle"] &&
      errors["licenses.0.billing_cycle"].length
        ? errors["licenses.0.billing_cycle"]
        : [];
  }
  /**
   * Clears all form errors from form
   * @vuese
   */
  clearErrors() {
    this.errors.licenseCount = [];
    this.errors.billingCycle = [];
  }

  /**
   * Make a request to add licenses to an thisAccount
   * @vuese
   */
  async requestLicenses(deets: any) {
    switch (this.typeOfAccount) {
      case Types.Role.SvAdmin:
        this.addLicensesToDistributorAccount({
          distributorId: +this.$route.params.id,
          updateDistributorLicenses: {
            licenses: [{ ...deets }],
          },
        })
          .then(() => {
            this.refresh();
            this.selectedLicense = null;
            this.listOfAvailableLicensesIsVisible = false;
            this.$toast.success(
              `${deets.count} Licenses Assigned To ${this.userName}`
            );
            this.isAdding = false;
          })
          .catch(({ errors }) => {
            this.mapErrors(errors);
            this.$toast.error(
              `Failed To ${deets.count} Assign Licenses To ${this.userName}`
            );
          });
        break;
      case Types.Role.Distributor:
        this.addLicensesToCompanyAccount({
          companyId: +this.$route.params.id,
          updateCompanyLicenses: {
            licenses: [{ ...deets }],
          },
        })
          .then(() => {
            this.refresh();
            this.selectedLicense = null;
            this.listOfAvailableLicensesIsVisible = false;
            this.$toast.success(
              `${deets.count} Licenses Assigned To ${this.userName}`
            );
            this.isAdding = false;
          })
          .catch(({ errors }) => {
            this.mapErrors(errors);
            this.$toast.error(
              `Failed To ${deets.count} Assign Licenses To ${this.userName}`
            );
          });
      default:
        break;
    }
  }

  addLicenses() {
    this.isAdding = true;
    if (this.isOnTrial) 
      this.showTrialOverridePrompt();
    else 
      this.showListOfAvailableLicenses();
  }

  /**
   * Show the `available licenses` card
   */
  showListOfAvailableLicenses() {
    this.hideLicenseSummary();
    this.listOfAvailableLicensesIsVisible = true;
  }

  /**
   * Hide the `available licenses` card
   */
  hideListOfAvailableLicenses() {
    this.hideLicenseSummary();
    this.isAdding = false;
    this.listOfAvailableLicensesIsVisible = false;
  }
}
