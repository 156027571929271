



























// @vuese
// @group Tables
// License Inventory Tables

import 'reflect-metadata'
import Component from 'vue-class-component'
import TableLoading from './TableLoading.vue'
import { Prop, Emit, Watch} from 'vue-property-decorator'
import { Store, TAwait } from '@/store/store'

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
  length?: number;
}

@Component({
  components:{
    TableLoading
  }
})
/**
 * Table that renders the currently active licenses in a users account
 */
export default class TrialLicenseTable extends Store {
  componentTitle = 'Trial License Table';

  @Prop(Number) readonly id!: number // id of the account to be queried
  @Prop(String) readonly fetch!: string // Refreshes the table data. Triggers whenevet the value changes

  trial = {
    trial_extended: false,
    trial_ends_at: '',
    trial_expired_at: '',
  }
  customerUsers: Array<CustomerUsers> = [];

  get fields(){
    return [
      {key: 'admin_letter', label: '', tdClass: 'admin-letter'},
      {key: 'email', label: 'Email', tdClass: 'email w20'},
      {key: 'meeting_room_alias', label: 'Alias'},
      {key: 'created_at', label: 'Invited'},
      {key: 'activated_at', label: 'Activated'},
      {key: 'is_pending', label: 'Status', tdClass: 'status'},
      {key: 'days_left', label: 'Days Remaining'},
       {
        key: 'manage',
        label: 'Extend',
      },
    ]
  }

  @Watch('fetch')
  onFetch(){
    this.refresh()
  }

  // account details
  user?: TAwait<ReturnType<typeof Store.prototype.getAccount>> | null = null
  async mounted(){
      await this.refresh()
  }
  /**
   * refresh data
   * @vuese
   */
  async refresh(){
    if (this.id) {
      const companyAccount = await this.getCompanyAccount(this.id + '').catch(() => {})

      if(companyAccount) {
        // trial details
        this.trial.trial_extended = companyAccount.extended_trial;
        this.trial.trial_ends_at = companyAccount.trial_ends_at;
        this.trial.trial_expired_at = companyAccount.trial_expired_at;
        // Save users
        this.customerUsers = (companyAccount.users as unknown) as CustomerUsers[];
      }
    }

    const user = await this.getAccount(this.id)
    if(user){
      this.user = user
    }
  }
  
  get items(){
    const output: any = [];
    
    this.customerUsers?.forEach((license) => {
      output.push({
        ...license,
        ...this.trial,
        view: true })
    })
    
    return output
  }

  extendTrial(id:Number) {
    this.extendTrialLicence(
      this.id
    )
    .then(() => {
      this.$toast.success('Trial Licences extended')
      this.refresh()
    })
    .catch(() => {
      this.$toast.error('Failed to extend Trial Licences')
    })
  }
}
