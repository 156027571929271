






























// @vuese
// @group Tables
// License Inventory Tables

import 'reflect-metadata'
import Component from 'vue-class-component'
import TableLoading from './TableLoading.vue'
import { Prop, Emit, Watch} from 'vue-property-decorator'
import { Store, TAwait } from '@/store/store'
import GenericPopup from '@/components/popup/GenericPopup.vue'
import LiceneseCahnges from '@/components/tables/LicenseChanges.vue'
@Component({
  components:{
    TableLoading,
    GenericPopup,
    LiceneseCahnges
  }
})
/**
 * Table that renders the all licenses associated with an account
 */
export default class LicenseInventoryTable extends Store{
  componentTitle = 'License Inventory Table';

  @Prop(Number) readonly accountId!: number // id of the account to be queried
  /**
   * Renders a `view` button to the table which emits an event
   */
  @Prop(Boolean) readonly showPreviewOption!: number 
  /**
   * Renders a `changes` button to the table which emits an event
   */
  @Prop(Boolean) readonly showChangesOption!: number 
  /**
   * Refreshes the table data. Triggers whenevet the value changes
   */
  @Prop(String) readonly fetch!: string

  changesPopup = false

  get fields(){
    return [
      'name', 
      this.isDistributor ? 'billing_cycle' : null,
      'assigned', 
      'available', 
      'used_monthly',
      {
        key: 'used_annual',
        label: 'Used Annually',
      },
      this.isAdmin ? 'changes' : null,
      this.showPreviewOption ? {
        key: 'view',
        label: '',
        class: 'w10',
      } : null]
  }

  @Watch('fetch')
  onFetch(){
    this.refresh()
  }

  // account details
  items?: TAwait<ReturnType<typeof Store.prototype.getListOfLicensesAssociatedWithAnAccount>> | null = null
  async mounted(){
      await this.refresh()
  }
  /**
   * refresh data
   * @vuese
   */
  async refresh(){
    this.items = await this.getListOfLicensesAssociatedWithAnAccount(this.accountId)
  }

  /**
   * Fire when the view button is clicked
   * @arg license - value representing the license that was selcted
   */
  @Emit()
  view(license: any){
    const uniqueKey = license.key + '_' + license.billing_cycle
    return uniqueKey;
  }

  @Emit()
  showChanges(license: any){
    return license.key as string
  }

}
