






















// @vuese
// @group Popup
// Password Popup
import { Component, Emit, Prop } from 'vue-property-decorator'
import GenericPopup from '@/components/popup/GenericPopup.vue'
import GenericForm from '@/components/forms/GenericForm.vue'
import Vue from 'vue'

@Component({
  components: {
    GenericPopup,
    GenericForm,
  }
})
/**
 * A popup that contains confirmation buttons
 */
export default class ConfirmationPopup extends Vue {

  /**
   * Fires when the form is submitted
   * @vuese
   */
  @Emit()
  confirm(){}

  /**
   * Fires when a users tries to close the popup
   * @vuese
   */
  @Emit()
  close(){}
}
