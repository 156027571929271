

















// @vuese
// @group List
import Component from 'vue-class-component';
import { Store, TAwait, TElement } from '@/store/store';
import { Emit } from 'vue-property-decorator';

@Component
/**
 * Renders all licenses that are aviable to this user
 */
export default class ListOfLicenses extends Store {
    items: TAwait<ReturnType<typeof Store.prototype.getListOfAssingableLicenses>> = []
    selectedLicense = ''

    async mounted(){
      await this.refresh()
    }

    /**
     * Refreshes the data in the list
     * @vuese
     */
    async refresh(){
      this.selectedLicense = ''
      this.items = await this.getListOfAssingableLicenses().catch((err) => {
        
        return []
      })
    }

    get sortedItems() {
      if (this.items) {
        return this.items.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
    }

    /**
     * Fires when the user clicks the `view` button
     */
    @Emit()
    view(license: TElement<typeof ListOfLicenses.prototype.items>){
      this.selectedLicense = license.name
      return license.key
    }

    /**
     * Is this license selected
     */
    isSelectedLicense(name: string) {
      return this.selectedLicense === name
    }
}
